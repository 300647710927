import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'


import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyCnB_ieLptiIiGRzE-DXpcsUJ6rQP2WHYM",
  authDomain: "futmarket-transfers.firebaseapp.com",
  projectId: "futmarket-transfers",
  storageBucket: "futmarket-transfers.appspot.com",
  messagingSenderId: "939639879618",
  appId: "1:939639879618:web:5c37081c2ae08f8310485b",
  measurementId: "G-9GEZ3HMQBQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
