const appConfig = {
    //apiPrefix: 'https://api.futmarket.net/api',
    apiPrefix: 'http://localhost:4000/api',
    authenticatedEntryPath: '/app/sales/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    enableMock: false,
    apiUrl: 'http://localhost:4000'
}

export default appConfig
